import React from "react";
import "../css/site.css";
import gallery_data from "../data/gallery.json";
import Photo from './Photo.js'

export default function Gallery() {
  return (
    <section className="small-section">
        <h1>Gallery</h1>
        <p>For more examples, check out my instagram: <a className='card-link' href="https://www.instagram.com/dango.ploof/">@dango.ploof</a></p>
        <div className="card-row">
            {gallery_data.map((item) => (
                <Photo key={item.title} data={item} />
            ))}
        </div>
    </section>
  );
}
